import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify/lib/framework';

import {THEME} from '@/site/constants';

import {messagesEn, messagesFr, setLang} from '../i18n';

Vue.use(Vuetify);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: setLang(), // set locale
  messages: {...messagesEn, ...messagesFr}, // set locale messages
});

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {light: THEME},
    options: {
      customProperties: true,
    },
  },
  lang: {
    current: setLang(),
    t: (key: string, ...params: Array<string | number>) => String(i18n.t(key, params)),
  },
});
