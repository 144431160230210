















































































































import {Component, Vue} from 'vue-property-decorator';
import {validationMixin} from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';
import {Action, Getter} from 'vuex-class';

import {Links} from '@/site/interfaces';

import {mdiSvg, nameRoutes} from '../tools';

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      email: {required, email},
      password: {required},
    },
  },
})
export default class LoginModal extends Vue {
  @Action('login', {namespace: 'user'}) login!: (params: {
    email: string;
    password: string;
    brandId: string;
  }) => void;

  // @Action('forgottenPassword', {namespace: 'user'}) forgottenPassword!: (email: string) => void;

  @Getter('error', {namespace: 'user'}) error!: boolean;

  @Getter('errorMessage', {namespace: 'user'}) errorMessage!: string;

  @Getter('brandId', {namespace: 'site'}) brandId!: string;

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('links', {namespace: 'site'}) links!: Links;

  svgIcons = mdiSvg;

  valid = false;

  form = {
    email: '',
    password: '',
  };

  submitForm(): void {
    this.login({...this.form, brandId: this.brandId});
  }

  resetPassword(): void {
    // if (this.emailErrors().length === 0) this.forgottenPassword(this.form.email);
    this.$emit('closeModal');
    this.$router.push({name: nameRoutes.resetPassword, params: {slug: this.$route.params.slug}});
  }

  createAccount(): void {
    // if (this.emailErrors().length === 0) this.forgottenPassword(this.form.email);
    this.$emit('closeModal');
    this.$router.push({name: nameRoutes.createAccount, params: {slug: this.$route.params.slug}});
  }

  resetForm(): void {
    this.$v.$reset();
    this.form = {
      email: '',
      password: '',
    };
    this.$emit('closeModal');
  }

  emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.form.email?.$dirty) return errors;
    if (!this.$v.form.email?.email) errors.push("L'email doit être valide");
    if (!this.$v.form.email?.required) errors.push('Ce champ est obligatoire');
    return errors;
  }

  passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.form.password?.$dirty) return errors;
    if (!this.$v.form.password?.required) errors.push('Ce champ est obligatoire');
    return errors;
  }
}
