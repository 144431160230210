

































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';

import BaseService from './BaseService.vue';
import LoginModal from './LoginModal.vue';

@Component({components: {LoginModal}})
export default class Account extends BaseService {
  @Prop() fromAppBar!: boolean;

  sheet = false;

  items = [
    {
      name: '🇬🇧 Anglais',
      nameEn: '🇬🇧 English',
      code: 'en',
    },
    {
      name: '🇫🇷 Français',
      nameEn: '🇫🇷 French',
      code: 'fr',
    },
  ];

  @Watch('$store.state.user.accessToken')
  updateDialog(): void {
    this.loginDialog = !this.accessToken;
  }

  setLng(evt: string) {
    this.$vuetify.lang.current = evt;
    localStorage.setItem('lng', this.$vuetify.lang.current);
    window.location.reload();
  }
}
