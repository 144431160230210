



























































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {mdiSvg} from '@/core';
import NoData from '@/core/components/NoData.vue';

import {Media} from '../interfaces/Media';
import {MediaItem} from '../interfaces/MediaItem';
import MediaItemModal from './MediaItemModal.vue';

@Component({
  components: {
    NoData,
    MediaItemModal,
  },
})
export default class Medias extends Vue {
  @Getter('medias', {namespace: 'media'}) medias!: Media[];

  svgIcons = mdiSvg;

  itemDialog = false;

  currentItem: MediaItem | null = null;

  today = new Date();

  panel = [0];

  formatDate(date: string): string {
    return new Date(date).toLocaleString('fr-fr', {day: 'numeric', month: 'long'});
  }

  openModal(item: MediaItem): void {
    this.currentItem = item;
    this.itemDialog = true;
  }

  filterItems(media: Media): MediaItem[] {
    return (
      media.items?.filter(
        (i) => i.images.length && this.today.getTime() < new Date(i.end).getTime()
      ) || []
    );
  }
}
