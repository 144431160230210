



























import {Component, Prop, Vue} from 'vue-property-decorator';

import {mdiSvg} from '@/core';

import {MediaItem} from '../interfaces/MediaItem';

@Component({})
export default class MediaItemModal extends Vue {
  @Prop() mediaItem!: MediaItem | null;

  svgIcons = mdiSvg;
}
