import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.booking)?_c(VContainer,{staticClass:"fill-height flex-column justify-center"},[_c('div',{staticClass:"white pa-4 pa-md-8 rounded"},[_c('p',{staticClass:"py-5 text-center"},[_vm._v(" Vous êtes sur le point d'annuler votre réservation du "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getFormatDate(_vm.booking.date)))]),_vm._v(" à "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getFormatTime(_vm.booking.date)))]),_vm._v(" pour "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.booking.seats))]),_vm._v(" "+_vm._s(_vm.booking.seats > 1 ? 'personnes' : 'personne')+", cette action est irrémédiable. ")]),_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"700px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('transition',{attrs:{"name":"fab","appear":""}},[_c('div',{staticClass:"d-flex justify-center FloatingButton"},[_c(VBtn,{staticClass:"FloatingButton__btn text-none mr-5 white--text font-weight-light",attrs:{"elevation":"0","large":"","color":"black","to":{
                name: _vm.$vuetify.breakpoint.smAndDown ? _vm.routes.home : _vm.routes.clickAndCollect,
                params: {slug: _vm.$route.params.slug},
              }}},[_vm._v(" Annuler")]),_c(VBtn,_vm._g(_vm._b({staticClass:"FloatingButton__btn text-none white--text font-weight-light",attrs:{"elevation":"0","large":"","color":"primary"},on:{"click":function($event){return _vm.onCancelBooking()}}},'v-btn',attrs,false),on),[_vm._v(" Confirmer")])],1)])]}}],null,false,1676518300),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"Reservation__card d-flex flex-column justify-center align-center py-md-10"},[(_vm.response && !_vm.error)?_c('h2',{staticClass:"text-h5 font-weight-black mb-4 text-center cta--text"},[_vm._v(" Votre réservation a été annulée ")]):_c('h2',{staticClass:"text-h5 font-weight-black mb-4 text-center cta--text"},[_vm._v("Erreur")]),_c(VImg,{attrs:{"contain":"","max-height":"300","src":_vm.response
              ? require('../../core/assets/images/bg/ok.jpg')
              : require('../../core/assets/images/bg/notfound.png')}}),(_vm.response && !_vm.error)?_c(VContainer,{staticClass:"px-8 pb-2 white--text"},[_c('p',{staticClass:"text-body-2 text-center"},[_vm._v(" Un récapitulatif a été envoyé sur votre adresse mail pour la réservation du "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getFormatDate(_vm.booking.date)))]),_vm._v(" à "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getFormatTime(_vm.booking.date)))]),_vm._v(" pour "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.booking.seats))]),_vm._v(" "+_vm._s(_vm.booking.seats > 1 ? 'personnes' : 'personne')+". ")])]):_c(VContainer,{staticClass:"px-8 pb-2 white--text"},[(_vm.errorMessage)?_c('p',{staticClass:"text-body-2 text-center"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_c('p',{staticClass:"text-body-2 text-center"},[_vm._v(" Une erreur s'est produite,veuillez réessayer plus tard ")])]),_c('div',{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"Form__btn text-none font-weight-medium",attrs:{"elevation":"0","large":"","color":"cta","to":{
              name: _vm.$vuetify.breakpoint.smAndDown ? _vm.routes.home : _vm.routes.clickAndCollect,
              params: {slug: _vm.$route.params.slug},
            },"exact":""},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" Retourner à l’accueil")])],1),_c('a',{staticClass:"pt-2 caption",on:{"click":function($event){_vm.reset(), _vm.goBooking()}}},[_vm._v(" Créer une nouvelle réservation ")])],1)],1)],1)]):(_vm.loading)?_c('div',[_c(VOverlay,[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":"","size":"64","width":"5"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }