




























































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import ModalComment from '../../feedback/components/ModalComment.vue';
import {Product} from '../interfaces';
import BaseProduct from './BaseProduct.vue';
import ModalProduct from './ModalProduct.vue';

@Component({
  components: {
    ModalComment,
    ModalProduct,
  },
})
export default class ProductCard extends BaseProduct {
  @Prop() readonly product!: Product;

  @Getter('enableFeedback', {namespace: 'site'}) enableFeedback!: boolean;

  @Watch('$route.hash')
  updateDialog(newHash: string, oldHash: string): void {
    if (newHash === `#${this.product.name}`) {
      this.dialog = true;
    } else if (oldHash === `#${this.product.name}`) {
      this.dialog = false;
    }
  }

  dialog = false;
}
