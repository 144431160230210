import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';

import {articleRoutes} from '@/articles/router';
import {bookingRoutes} from '@/bookings/router';
import {feedbackRoutes} from '@/feedback/router';
import {mediaRoutes} from '@/medias/router';
import {menuRoutes} from '@/menu/router';

import {store} from './store';
import {formatSlug, nameRoutes} from './tools/router.tools';
import Affluence from './views/Affluence.vue';
import Badge from './views/Badge.vue';
// import Cgu from './views/Cgu.vue';
import CreateAccount from './views/CreateAccount.vue';
import Home from './views/Home.vue';
// import MentionsLegales from './views/MentionsLegales.vue';
import Other from './views/Other.vue';
import PageNotFound from './views/PageNotFound.vue';
import ResetPassword from './views/ResetPassword.vue';
import RoomService from './views/RoomService.vue';
import UpdatePassword from './views/UpdatePassword.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/non-trouve',
  },
  {
    path: '/non-trouve',
    name: nameRoutes.notFound,
    component: PageNotFound,
    meta: {title: 'Page introuvable'},
  },
  {
    path: '/:slug?',
    name: nameRoutes.home,
    component: Home,
    meta: {title: 'Accueil'},
  },
  {
    path: '/:slug?/compte',
    name: nameRoutes.badge,
    component: Badge,
    meta: {title: 'Compte'},
  },
  {
    path: '/:slug?/creation-compte',
    name: nameRoutes.createAccount,
    component: CreateAccount,
    meta: {title: 'Création de compte'},
  },
  {
    path: '/:slug?/reinitialisation-mot-de-passe',
    name: nameRoutes.resetPassword,
    component: ResetPassword,
    meta: {title: 'Réinitialisation mot de passe'},
  },
  {
    path: '/:slug?/modification-mot-de-passe',
    name: nameRoutes.updatePassword,
    component: UpdatePassword,
    meta: {title: 'Modification mot de passe'},
  },
  {
    path: '/:slug?/emporter',
    name: nameRoutes.clickAndCollect,
    meta: {title: 'À emporter'},
  },
  {
    path: '/:slug?/roomservice',
    name: nameRoutes.roomService,
    component: RoomService,
    meta: {title: 'Room Service'},
  },
  {
    path: '/:slug?/affluence',
    name: nameRoutes.affluence,
    component: Affluence,
    meta: {title: 'Affluence'},
  },
  {
    path: '/:slug?/autre',
    name: nameRoutes.other,
    component: Other,
    meta: {title: 'Autre'},
  },
  // {
  //   path: '/:slug?/MentionsLegales',
  //   name: 'legalNotice',
  //   component: MentionsLegales,
  //   meta: {title: 'Mentions Légales'},
  // },
  // {
  //   path: '/:slug?/cgu',
  //   name: 'termsOfService',
  //   component: Cgu,
  //   meta: {title: 'CGU'},
  // },
  ...menuRoutes,
  ...articleRoutes,
  ...bookingRoutes,
  ...feedbackRoutes,
  ...mediaRoutes,
  {
    path: '/*',
    redirect: '/non-trouve',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
  if (to.name !== 'home' && !to.hash) window.scrollTo(0, 0);

  if (to.params.slug) {
    const formattedSlug = formatSlug(to.params.slug);
    if (
      formattedSlug.slug &&
      (!router.app.$store.getters['site/customerId'] ||
        router.app.$store.getters['site/slug'] !== to.params.slug)
    )
      store.dispatch('site/getSite', to.params.slug);

    if (formattedSlug.portal && router.app.$store.getters['site/siteList'].length === 0)
      store.dispatch('site/getSiteList', formattedSlug.portal);
  }

  next();
});
export default router;
