













































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import AppBar from '@/core/components/AppBar.vue';
import Footer from '@/core/components/Footer.vue';
import ClickAndCollect from '@/core/views/ClickAndCollect.vue';

import {mdiSvg, nameRoutes} from './core';
import AppleMessagePWA from './core/components/AppleMessagePWA.vue';
import Banner from './core/components/Banner.vue';
import BottomBar from './core/components/BottomBar.vue';
import LanguageModal from './core/components/LanguageModal.vue';
import {Day} from './menu/interfaces';
import {Links} from './site/interfaces';

@Component({
  components: {
    AppBar,
    Banner,
    Footer,
    BottomBar,
    AppleMessagePWA,
    ClickAndCollect,
    LanguageModal,
  },
})
export default class App extends Vue {
  @Action('me', {namespace: 'user'}) me!: (accessToken: string) => void;

  @Action('accessToken', {namespace: 'user'}) setAccessToken!: (accessToken: string) => void;

  @Getter('error', {namespace: 'site'}) error!: boolean;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('days', {namespace: 'menu'}) days!: Day[];

  @Getter('slug', {namespace: 'site'}) slug!: string;

  @Getter('menusAvailable', {namespace: 'menu'}) menusAvailable!: boolean;

  @Getter('links', {namespace: 'site'}) links!: Links;

  @Getter('enableAffluence', {namespace: 'site'}) enableAffluence!: boolean;

  @Getter('enableBooking', {namespace: 'site'}) enableBooking!: boolean;

  @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('hasBookingConfig', {namespace: 'booking'}) hasBookingConfig!: boolean;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('enableI18n', {namespace: 'site'}) enableI18n!: boolean;

  routes = nameRoutes;

  svgIcons = mdiSvg;

  worker: Worker | null = null;

  refreshing = false;

  isOffline = false;

  updateExists = false;

  languageDialog = false;

  @Watch('$store.state.site.name')
  private updateTitle() {
    if (this.siteName) {
      document.title = this.siteName;
      this.setManifest();
    }
  }

  @Watch('$store.state.site.error')
  private onError() {
    if (this.error && this.$route.name !== this.routes.notFound) {
      document.title = 'Page introuvable';
      this.$router.replace('/non-trouve');
    }
  }

  @Watch('$store.state.menu.menus')
  private redirectClick() {
    if (this.$vuetify.breakpoint.smAndUp && this.$route.name === this.routes.home) {
      this.$router.push({
        name: this.getNextServiceAvailable(),
        params: {slug: this.slug},
      });
    }
  }

  @Watch('$store.state.site.enableI18n')
  private changeLanguage() {
    if (this.enableI18n) {
      this.getLanguage();
    }
  }

  created(): void {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
    document.addEventListener('swOffline', this.showOfflineUI, {once: true});

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        // triggered by registration.claim
        'controllerchange',
        () => {
          if (this.refreshing) return;
          this.refreshing = true;
          // console.log('controller change triggered, -> auto refresh!!');
          window.location.reload();
        }
      );
    }
  }

  mounted(): void {
    document.title = 'BaxterStorey';

    const accessToken = window.localStorage.getItem('accessToken');
    const userId = window.localStorage.getItem('userId');

    if (accessToken && userId) {
      this.setAccessToken(accessToken);
      this.me(accessToken);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  showRefreshUI(e: any): void {
    // Display a button inviting the user to refresh/reload the app due
    // to an app update being available.
    // The new service worker is installed, but not yet active.
    // Store the ServiceWorkerRegistration instance for later use.

    if (e.detail) {
      this.worker = e.detail;
      this.updateExists = true;
      // console.log('Showing refresh button.');
      // console.log(this.worker, 'worker');
    } else {
      console.warn('No worker data found!');
    }
  }

  showOfflineUI(): void {
    this.isOffline = true;
  }

  refreshApp(): void {
    this.updateExists = false;
    if (!this.worker) {
      console.warn('No worker data found when trying to refresh!');
    } else this.worker.postMessage({type: 'SKIP_WAITING'});
  }

  private setManifest(): void {
    const manifestElement = document.getElementById('manifest');
    const manifestString = JSON.stringify({
      id: this.slug,
      name: this.siteName,
      short_name: this.siteName,
      start_url: `${window.location.origin}/${this.slug}`,
      display: 'standalone',
      icons: [
        {
          src: `${window.location.origin}/icon-192x192.png`,
          sizes: '192x192',
          type: 'image/png',
        },
        {
          src: `${window.location.origin}/icon-512x512.png`,
          sizes: '512x512',
          type: 'image/png',
        },
      ],
      theme_color: '#0B715E',
      scope: window.location.origin,
    });
    if (manifestElement) {
      manifestElement.setAttribute(
        'href',
        `data:application/json;charset=utf-8,${encodeURIComponent(manifestString)}`
      );
    }
  }

  getNextServiceAvailable() {
    if (this.menusAvailable) return this.routes.menu;
    if (this.links.clickAndCollectUrl) return this.routes.clickAndCollect;
    if (this.links.badgeUrl) return this.routes.badge;
    if (this.links.affluenceUrl) return this.routes.affluence;
    if (this.links.roomServiceUrl) return this.routes.roomService;
    if (this.hasArticles) return this.routes.articles;
    if ((this.enableBooking && !!this.hasBookingConfig) || this.links.bookingUrl)
      return this.routes.booking;
    if (this.enableContact) return this.routes.contact;
    return this.routes.home;
  }

  getLanguage() {
    const lng = localStorage.getItem('lng');
    if (!lng) {
      const browserLang = navigator.language.split('-')[0];
      this.$vuetify.lang.current = browserLang;
      this.languageDialog = true;
    }
  }
}
