import spacetime from 'spacetime';

export const DAYS = [
  {weekDay: 1, name: 'lun.', id: 'monday'},
  {weekDay: 2, name: 'mar.', id: 'tuesday'},
  {weekDay: 3, name: 'mer.', id: 'wednesday'},
  {weekDay: 4, name: 'jeu.', id: 'thursday'},
  {weekDay: 5, name: 'ven.', id: 'friday'},
  // {weekDay: 6, name: 'samedi'},
  // {weekDay: 0, name: 'dimanche'},
];
export const spacetimeFr = {
  days: {
    long: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    short: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
  },
  months: {
    long: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
    short: [
      'jan',
      'fév',
      'mars',
      'avril',
      'mai',
      'juin',
      'juil',
      'août',
      'sept',
      'oct',
      'nov',
      'déc',
    ],
  },
};

export const spacetimeEn = {
  days: {
    long: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  months: {
    long: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
};
export const tzParis = 'Europe/Paris';

export function formatShortDate(date: string): string[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateSpace: any = spacetime(date, tzParis);
  const lng = localStorage.getItem('lng') || 'fr';
  switch (lng) {
    case 'en':
      dateSpace.i18n(spacetimeEn);
      break;
    case 'fr':
      dateSpace.i18n(spacetimeFr);
      break;
    default:
      dateSpace.i18n(spacetimeFr);
      break;
  }
  return [dateSpace.format('{date-pad}').toString(), dateSpace.format('{day-short}').toString()];
}
export function formatDate(date: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateSpace: any = spacetime(date, tzParis);
  const lng = localStorage.getItem('lng') || 'fr';
  switch (lng) {
    case 'en':
      dateSpace.i18n(spacetimeEn);
      break;
    case 'fr':
      dateSpace.i18n(spacetimeFr);
      break;
    default:
      dateSpace.i18n(spacetimeFr);
      break;
  }
  return dateSpace.format('{day-short}. {date} {month-short} {year}').toString();
}
export function formatTime(date: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateSpace: any = spacetime(date, tzParis).goto(tzParis);
  const lng = localStorage.getItem('lng') || 'fr';
  switch (lng) {
    case 'en':
      dateSpace.i18n(spacetimeEn);
      break;
    case 'fr':
      dateSpace.i18n(spacetimeFr);
      break;
    default:
      dateSpace.i18n(spacetimeFr);
      break;
  }
  return dateSpace.format('{hour-24-pad}:{minute-pad}').toString();
}
export function isEqualDate(d: string, n: string): boolean {
  return spacetime(d, tzParis).isSame(spacetime(n, tzParis), 'date');
}
export function isToday(n: string): boolean {
  return spacetime.now(tzParis).isSame(spacetime(n, tzParis), 'date');
}
export function isBeforeDate(m: string | number, n: string | number): boolean {
  return spacetime(m, tzParis).isBefore(spacetime(n, tzParis));
}

export function setHoursAndMinutes(hour: string, date: Date): string {
  const {isDST} = spacetime().timezone().current;
  const dateSp = spacetime(date, tzParis)
    .hour(parseInt(hour.split(':')[0], 10) - (isDST ? 0 : 1))
    .minute(parseInt(hour.split(':')[1], 10))
    .second(0)
    .millisecond(0);
  return `${dateSp.format('iso-utc')}`;
}
