import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"Language Language__card"},[_c('div',{staticClass:"pa-0 relative Language__container d-flex flex-column fill-width"},[_c('div',{staticClass:"Language__container--banner d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"mr-4 mt-4",attrs:{"color":"white","small":"","width":"36","height":"36","right":"","depressed":""},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.close)+" ")])],1)],1),_c('div',{staticClass:"d-flex justify-center py-16"},[_c(VImg,{staticClass:"flex-grow-0",attrs:{"src":require('../assets/images/logos/logo_white.svg'),"contain":"","width":"150","height":"100","position":"center"}})],1)]),_c('div',{staticClass:"pa-4 pt-10"},[_c('span',{staticClass:"cta--text Language__container--title"},[_vm._v(" "+_vm._s(_vm.currentText.title))]),_c(VForm,{ref:"form",staticClass:"pt-8 Language__container--form",on:{"submit":function($event){$event.preventDefault();return _vm.setLng()}}},[_c(VSelect,{attrs:{"items":_vm.items,"item-text":_vm.$vuetify.lang.current === 'fr' ? 'name' : 'nameEn',"item-value":"code","label":"Solo field","dense":"","hide-details":"","solo":""},model:{value:(_vm.selectLang),callback:function ($$v) {_vm.selectLang=$$v},expression:"selectLang"}}),_c(VBtn,{staticClass:"mt-3 fill-width",attrs:{"elevation":"0","large":"","color":"cta","type":"submit","value":"Submit"}},[_c('span',{staticClass:"text-button font-weight-bold"},[_vm._v(_vm._s(_vm.currentText.cta))])])],1),_c('br'),_c('span',{staticClass:"pt-4 white--text"},[_vm._v(_vm._s(_vm.currentText.helper))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }