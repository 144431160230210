import VueI18n from 'vue-i18n';
import {fr} from 'vuetify/src/locale';

export const messagesFr: VueI18n.LocaleMessages = {
  fr: {
    $vuetify: {
      ...fr,
      close: 'Fermer',
      loading: 'Chargement...',
      next: 'Suivant',
      home: 'Accueil',
      goHome: "Retour à l'accueil",
      form: {
        submit: 'Valider',
        required: 'Ce champ est obligatoire',
        optional: '(facultatif)',
        lastName: 'Nom',
        firstName: 'Prénom',
        email: 'Adresse mail',
        phoneNumber: 'Numéro de téléphone',
        commentary: 'Commentaire',
        errorEmail: "L'email doit être valide",
        errorPhone: 'Le numéro de téléphone doit être valide',
        placeholderMessage: 'Décrivez votre demande ici',
      },
      services: {
        menuDescription: 'Consulter la carte du restaurant',
        newsDescription: 'Voir les actus de votre restaurant',
        eWalletDescription: 'Rechargez votre carte en ligne',
        clickCollectDescription: 'Plats en vente à emporter',
        roomServiceDescription: 'Room Service',
        contactDescription: 'Vous avez une question ?',
        mediaDescription: 'Consulter les informations du restaurant',
        affluenceDescription: 'Trouver le moment ideal pour manger',
        accountDescription: 'Accéder à votre compte personnel',
        bookingDescription: 'Réservation de table',
        shortNameClickCollect: 'Vente à Emporter',
        shortNameAffluence: 'Affluence',
        shortNameAccount: 'Mon Compte',
        shortNameMedias: 'Médias',
        shortNameBooking: 'Réservation',
        shortNameNews: 'Actualités',
        shortNameOthers: 'Autres',
      },
      banner: {
        title: 'Bienvenue dans votre espace de restauration',
        menuTitle: 'Consultez le menu de la semaine',
        articleTitle: 'Découvrez nos dernières actualités',
        badgeTitle: 'Accédez à votre compte badge',
        clickAndCollectTitle: 'Commande en ligne',
        roomServiceTitle: 'Room Service',
        contactTitle: 'Formulaire de contact',
        resetPasswordTitle: 'Réinitialisation mot de passe',
        updatePasswordTitle: 'Modification mot de passe',
        mediaTitle: 'Consulter les informations du restaurant',
        bookingTitle: 'Réserver une table',
        cancelBookingTitle: 'Annuler votre réservation?',
        affluenceTitle: 'Affluence',
        otherTitle: 'Autres',
        bookingStepOneTitle: '1. Détails de la réservation',
        bookingStepTwoTitle: '2. Confirmation de la réservation',
      },
      menu: {
        allergens: {
          ARA: 'Arachides',
          CEL: 'Céleri',
          CRU: 'Crustacés',
          FCQ: 'Fruits à coque',
          GLU: 'Gluten',
          LAI: 'Lait',
          LUP: 'Lupin',
          MOL: 'Mollusques',
          MOU: 'Moutarde',
          OEU: 'Oeuf',
          POI: 'Poisson',
          SES: 'Sésame',
          SOJ: 'Soja',
          SUL: 'Sulfite',
        },
        selectProductType: {
          ENT: 'Entrées',
          PLA: 'Plats',
          ACC: 'Accompagnements',
          DES: 'Desserts',
          BOI: 'Boissons',
          WRA: 'Wrap',
          FOC: 'Focaccia',
          SAL: 'Salades',
          BAG: 'Bagels',
          BRE: 'Baguettes',
        },
        modalProduct: {
          certification: 'Ce plat est certifié',
          sideDishes: 'Les Accompagnements',
          price: 'Prix',
        },
      },
      affluence: {
        title: 'Affluence',
        description:
          "Consultez l'affluence de votre restaurant pour trouver le meilleur horaire pour venir manger.",
        days: {
          monday: 'Lun.',
          tuesday: 'Mar.',
          wednesday: 'Mer.',
          thursday: 'Jeu.',
          friday: 'Ven.',
        },
        direct: 'En direct',
        helper: "Cliquez sur les données d'affluence pour avoir plus d'infos",
        redirectNow: "Consulter l'affluence en direct",
        emptyStep: 'Aucun palier sélectionné',
      },
      booking: {
        createNewBooking: 'Créer une nouvelle réservation',
        hour: 'Heure',
        countSeats: 'Nombre de personnes',
        errorSeats: 'Veuillez saisir un nombre de personne supérieure à 0',
        infoThresholdPartOne: 'Pour toute réservation au-delà de',
        infoThresholdPartTwo:
          'votre réservation devra être validée par notre responsable. Vous recevrez un retour par mail dans les meilleurs délais, merci.',
        shortPeople: 'pers.',
        response: {
          successRegisteredTitle: 'Votre réservation a été enregistrée',
          successConfirmedTitle: 'Votre reservation est confirmée',
          successDescriptionAt: ' à',
          successDescriptionFor: 'pour',
          successRegisteredDescriptionPartOne: ' Merci pour votre demande de réservation pour le',
          successRegisteredDescriptionPartFour:
            'personnes, vous recevrez un retour par mail dans les meilleurs délais.',
          successConfirmedDescriptionPartOne:
            'Une confirmation a été envoyée sur votre adresse mail pour une réservation le',
          successConfirmedDescriptionPartFour:
            'personnes. Pour toute annulation, vous trouverez un lien dans votre mail de confirmation.',
          errorTitle: 'Erreur',
          error: 'Une erreur est survenue lors de la réservation',
          errorDescription: "Une erreur s'est produite,veuillez réessayer plus tard",
        },
      },
      contact: {
        title: 'Une question ? un problème ?',
      },
      account: {
        balance: 'Solde',
        recharge: 'Recharger ma carte',
        logout: 'Déconnexion',
        myAccount: 'Mon compte',
        accountDescription: 'Connectez-vous pour gérer et voir votre compte.',
      },
      login: {
        welcome: 'Bienvenue',
        account: 'sur votre espace personnel',
        login: 'Connexion',
        resetPassword: 'Mot de passe oublié ?',
        password: 'Mot de passe',
        connectMe: 'Me connecter',
        textCreateAccount: "Vous n'avez pas de compte ?",
        createAccount: 'Inscrivez-vous',
        textFirstConnection: 'Première connexion?',
        firstConnection: 'Activez votre compte',
      },
      helperLogin: {
        description:
          'Pour accéder à la vente à emporter ou au rechargement de carte, veuillez vous connecter à votre compte',
        login: 'Me connecter',
      },
    },
  },
};
