import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Banner tertiary d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown
      ? _vm.$route.name === _vm.routes.home
        ? 'Banner__home'
        : ''
      : 'Banner__desktop'},[_c(VContainer,{staticClass:"pa-0"},[(
        _vm.$route.name !== _vm.routes.menu &&
        _vm.$route.name !== _vm.routes.articles &&
        _vm.$route.name !== _vm.routes.clickAndCollect &&
        _vm.$route.name !== _vm.routes.badge &&
        _vm.$route.name !== _vm.routes.roomService &&
        _vm.$route.name !== _vm.routes.contact &&
        _vm.$route.name !== _vm.routes.resetPassword &&
        _vm.$route.name !== _vm.routes.updatePassword &&
        _vm.$route.name !== _vm.routes.medias &&
        _vm.$route.name !== _vm.routes.booking &&
        _vm.$route.name !== _vm.routes.cancelBooking &&
        _vm.$route.name !== _vm.routes.affluence &&
        _vm.$route.name !== _vm.routes.other
      )?_c('div',[_c('h1',{staticClass:"px-4 px-md-0 font-weight-bold cta--text text-uppercase title-page",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'text-h1Desktop pb-4'},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.banner.title'))+" ")])]):_c('div',[_c('h1',{staticClass:"px-4 px-md-0 font-weight-bold cta--text text-uppercase title-page",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'text-h1Desktop'},[_vm._v(" "+_vm._s(_vm.getNamePage(_vm.$route.name))+" ")]),(_vm.$route.name == _vm.routes.booking && !_vm.links.bookingUrl)?_c('p',{staticClass:"font-weight-bold px-4 px-md-0 white--text",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-subtitle-2' : 'text-h2'},[_vm._v(" "+_vm._s(_vm.step === 1 ? _vm.$vuetify.lang.t('$vuetify.banner.bookingStepOneTitle') : _vm.$vuetify.lang.t('$vuetify.banner.bookingStepTwoTitle'))+" ")]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }