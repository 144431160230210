


























































































































import {Component} from 'vue-property-decorator';

import AffluenceModal from './AffluenceModal.vue';
import BaseService from './BaseService.vue';

@Component({components: {AffluenceModal}})
export default class BottomBar extends BaseService {
  value = this.$route.name || '';

  sheet = false;
}
