




















import {Component} from 'vue-property-decorator';

import BaseService from './BaseService.vue';
import LoginModal from './LoginModal.vue';

@Component({components: {LoginModal}})
export default class HelperLogin extends BaseService {}
