





































































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {Links} from '@/site/interfaces';

import {nameRoutes} from '../tools';

@Component
export default class Banner extends Vue {
  @Getter('motd', {namespace: 'site'}) motd!: string | null;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('step', {namespace: 'booking'}) step!: number;

  @Getter('links', {namespace: 'site'}) links!: Links;

  routes = nameRoutes;

  getNamePage(route: string): string {
    switch (route) {
      case this.routes.menu:
        return this.$vuetify.lang.t('$vuetify.banner.menuTitle');
      case this.routes.articles:
        return this.$vuetify.lang.t('$vuetify.banner.articleTitle');
      case this.routes.badge:
        return this.$vuetify.lang.t('$vuetify.banner.badgeTitle');
      case this.routes.clickAndCollect:
        return this.$vuetify.lang.t('$vuetify.banner.clickAndCollectTitle');
      case this.routes.roomService:
        return this.$vuetify.lang.t('$vuetify.banner.roomServiceTitle');
      case this.routes.contact:
        return this.$vuetify.lang.t('$vuetify.banner.contactTitle');
      case this.routes.resetPassword:
        return this.$vuetify.lang.t('$vuetify.banner.resetPasswordTitle');
      case this.routes.medias:
        return this.$vuetify.lang.t('$vuetify.banner.mediaTitle');
      case this.routes.booking:
        return this.$vuetify.lang.t('$vuetify.banner.bookingTitle');
      case this.routes.cancelBooking:
        return this.$vuetify.lang.t('$vuetify.banner.cancelBookingTitle');
      case this.routes.affluence:
        return this.$vuetify.lang.t('$vuetify.banner.affluenceTitle');
      case this.routes.updatePassword:
        return this.$vuetify.lang.t('$vuetify.banner.updatePasswordTitle');
      default:
        return '';
    }
  }
}
