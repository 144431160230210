const brandsId: Record<string, string> = {
  demo: '496',
  GDCL: '1973',
  WPP: '1587',
  CTPK: '1793',
  RIHA: '2294',
  CAMI: '2295',
  MASL: '2296',
  CDG: '2546',
  OCDE: '2501',
  SOL: '1997',
  TD2: '2651',
};

export const getBrandId = (customerId: string): string => {
  return brandsId[customerId];
};
