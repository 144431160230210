export * from './en.trad';
export * from './fr.trad';

export const setLang = (): string => {
  const localStorageLng = localStorage.getItem('lng');
  if (localStorageLng) return localStorageLng;

  const navigatorLng = navigator.language.split('-')[0];
  if (navigatorLng !== 'fr' && navigatorLng !== 'en') return 'en';

  return navigatorLng;
};
