import {RouteConfig} from 'vue-router';

import Contact from './views/Contact.vue';

export const feedbackRoutes: Array<RouteConfig> = [
  {
    path: '/:slug?/contact',
    name: 'contact',
    component: Contact,
    meta: {title: 'Contact'},
  },
];
