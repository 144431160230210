import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.smAndDown)?_c(VContainer,{staticClass:"pa-0",class:!!_vm.customerId ? '' : 'fill-height',style:(!!_vm.customerId ? '' : 'margin-top: 1rem')},[(!!_vm.customerId && !_vm.loading)?_c('div',{staticClass:"d-flex flex-wrap justify-space-between"},[_vm._l((_vm.services.slice(0, 2)),function(service,index){return _c(VCard,{key:index,class:index > 1
          ? 'Card d-flex justify-space-between justify-md-space-around align-center mx-4 mb-4 mr-md-4 rounded'
          : index === 0
          ? 'Card__highlight d-flex flex-column mx-4 mr-2 mb-4 rounded flex-grow-1'
          : 'Card__highlight d-flex flex-column mx-4 ml-2 mb-4 rounded flex-grow-1',attrs:{"align":"left","min-height":index > 1 ? 96 : 200,"elevation":"0"},on:{"click":function($event){return _vm.selectService({
          name: service.name,
          params: {slug: _vm.$route.params.slug},
        })}}},[_c('div',{staticClass:"ma-3 d-flex"},[_c('div',{staticClass:"tertiary pa-3 rounded"},[_c(VIcon,{staticClass:"flex-grow-0",attrs:{"medium":"","color":"primary"}},[_vm._v(" "+_vm._s(service.icon)+" ")])],1),(index < 2)?_c('div',{staticClass:"flex-grow-1 mx-10"}):_vm._e()]),_c('div',{staticClass:"d-flex flex-column justify-start flex-grow-1"},[_c('h2',{staticClass:"text-h2 pl-4 font-weight-medium"},[_vm._v(" "+_vm._s(service.title)+" ")]),_c('span',{staticClass:"caption font-weight-regular px-4"},[_vm._v(" "+_vm._s(service.description)+" ")])]),(index < 2)?_c('div',{class:index > 1 ? '' : 'd-flex justify-end flex-grow-1 align-end pb-2'},[_c(VBtn,{staticClass:"text-none mr-4",attrs:{"elevation":"0","rounded":"","fab":"","x-small":"","color":"white"},on:{"click":function($event){return _vm.selectService({
              name: service.name,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c(VIcon,{attrs:{"large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.svgIcons.chevronRight)+" ")])],1)],1):_vm._e()])}),(_vm.mediaService.show)?_c(VCard,{staticClass:"Card d-flex justify-space-between justify-md-space-around align-center mx-4 mb-4 mr-md-4 rounded",attrs:{"align":"left","min-height":"96","elevation":"0"},on:{"click":function($event){return _vm.selectService({
          name: _vm.mediaService.name,
          params: {slug: _vm.$route.params.slug},
        })}}},[_c('div',{staticClass:"ma-3 d-flex"},[_c('div',{staticClass:"tertiary pa-3 rounded"},[_c(VIcon,{staticClass:"flex-grow-0",attrs:{"medium":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.mediaService.icon)+" ")])],1)]),_c('div',{staticClass:"d-flex flex-column justify-start flex-grow-1"},[_c('h2',{staticClass:"text-h2 pl-4 font-weight-medium",staticStyle:{"text-transform":"capitalize !important"}},[_vm._v(" "+_vm._s(_vm.mediaService.title)+" ")]),_c('span',{staticClass:"caption font-weight-regular px-4"},[_vm._v(" "+_vm._s(_vm.mediaService.description)+" ")])])]):_vm._e(),_vm._l((_vm.services.slice(2, _vm.services.length - 1)),function(service){return _c(VCard,{key:service.icon,staticClass:"Card d-flex justify-space-between justify-md-space-around align-center mx-4 mb-4 mr-md-4 rounded",attrs:{"align":"left","min-height":"96","elevation":"0"},on:{"click":function($event){return _vm.selectService({
          name: service.name,
          params: {slug: _vm.$route.params.slug},
        })}}},[_c('div',{staticClass:"ma-3 d-flex"},[_c('div',{staticClass:"tertiary pa-3 rounded"},[_c(VIcon,{staticClass:"flex-grow-0",attrs:{"medium":"","color":"primary"}},[_vm._v(" "+_vm._s(service.icon)+" ")])],1)]),_c('div',{staticClass:"d-flex flex-column justify-start flex-grow-1"},[_c('h2',{staticClass:"text-h2 pl-4 font-weight-medium"},[_vm._v(_vm._s(service.title))]),_c('span',{staticClass:"caption font-weight-regular px-4"},[_vm._v(" "+_vm._s(service.description)+" ")])])])}),(_vm.enableAffluence && _vm.customerId)?_c(VDialog,{attrs:{"persistent":_vm.$vuetify.breakpoint.smAndDown,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VCard,_vm._g(_vm._b({staticClass:"Card d-flex justify-space-between justify-md-space-around align-center mx-4 mb-4 mr-md-4 rounded",attrs:{"align":"left","min-height":"96","elevation":"0"}},'v-card',attrs,false),on),[_c('div',{staticClass:"ma-3 d-flex"},[_c('div',{staticClass:"tertiary pa-3 rounded"},[_c(VIcon,{staticClass:"flex-grow-0",attrs:{"medium":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.svgIcons.poll)+" ")])],1)]),_c('div',{staticClass:"d-flex flex-column justify-start flex-grow-1"},[_c('h2',{staticClass:"text-h2 pl-4 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.services.shortNameAffluence'))+" ")]),_c('span',{staticClass:"caption font-weight-regular px-4"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.services.affluenceDescription'))+" ")])])])]}}],null,false,2972360878),model:{value:(_vm.affluenceDialog),callback:function ($$v) {_vm.affluenceDialog=$$v},expression:"affluenceDialog"}},[_c('AffluenceModal',{on:{"closeModal":function($event){_vm.affluenceDialog = false}}})],1):_vm._e(),_c('Account',{attrs:{"fromAppBar":false}}),(_vm.contactService.show)?_c(VCard,{staticClass:"Card d-flex justify-space-between justify-md-space-around align-center mx-4 mb-4 mr-md-4 rounded",attrs:{"align":"left","min-height":"96","elevation":"0"},on:{"click":function($event){return _vm.selectService({
          name: _vm.contactService.name,
          params: {slug: _vm.$route.params.slug},
        })}}},[_c('div',{staticClass:"ma-3 d-flex"},[_c('div',{staticClass:"tertiary pa-3 rounded"},[_c(VIcon,{staticClass:"flex-grow-0",attrs:{"medium":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.contactService.icon)+" ")])],1)]),_c('div',{staticClass:"d-flex flex-column justify-start flex-grow-1"},[_c('h2',{staticClass:"text-h2 pl-4 font-weight-medium"},[_vm._v(_vm._s(_vm.contactService.title))]),_c('span',{staticClass:"caption font-weight-regular px-4"},[_vm._v(" "+_vm._s(_vm.contactService.description)+" ")])])]):_vm._e()],2):_vm._e(),(!_vm.customerId && !_vm.loading)?_c('Portal',{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],attrs:{"isOpenFromHome":false}}):_vm._e(),(_vm.loading)?_c('div',[_c(VOverlay,[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":"","size":"64","width":"5"}})],1)],1):_vm._e()],1):(!_vm.customerId && !_vm.loading)?_c('Portal'):_c('ClickAndCollect')}
var staticRenderFns = []

export { render, staticRenderFns }