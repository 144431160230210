
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {Media} from '@/medias/interfaces';
import SelectorSite from '@/site/components/SelectorSite.vue';
import {Links, ServiceLinks, Site} from '@/site/interfaces';

import {mdiSvg, nameRoutes} from '../tools';

@Component({components: {SelectorSite}})
export default class BaseService extends Vue {
  @Getter('links', {namespace: 'site'}) links!: Links;

  @Getter('hasMedias', {namespace: 'media'}) hasMedias!: boolean;

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('hasOneMedia', {namespace: 'media'}) hasOneMedia!: boolean;

  @Getter('medias', {namespace: 'media'}) medias!: Media[];

  @Getter('menusAvailable', {namespace: 'menu'}) menusAvailable!: boolean;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('isDemo', {namespace: 'site'}) isDemo!: boolean;

  @Getter('siteList', {namespace: 'site'}) siteList!: Site[];

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('enableAffluence', {namespace: 'site'}) enableAffluence!: boolean;

  @Getter('enableBooking', {namespace: 'site'}) enableBooking!: boolean;

  @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;

  @Getter('accessToken', {namespace: 'user'}) accessToken!: string;

  @Getter('balance', {namespace: 'user'}) balance!: number;

  @Getter('email', {namespace: 'user'}) email!: string;

  @Getter('logo2', {namespace: 'site'}) logo2!: string | null;

  @Getter('hasBookingConfig', {namespace: 'booking'}) hasBookingConfig!: boolean;

  @Getter('enableI18n', {namespace: 'site'}) enableI18n!: boolean;

  @Action('updateOpenSelector', {namespace: 'site'}) updateOpenSelector!: (
    isOpen: boolean
  ) => void;

  @Action('logout', {namespace: 'user'}) logout!: (token: string) => void;

  @Getter('isNewUser', {namespace: 'user'}) isNewUser!: boolean;

  affluenceDialog = false;

  loginDialog = false;

  svgIcons = mdiSvg;

  routes = nameRoutes;

  services: ServiceLinks[] = [];

  menuDescription = this.$vuetify.lang.t('$vuetify.services.menuDescription');

  newsDescription = this.$vuetify.lang.t('$vuetify.services.newsDescription');

  eWalletDescription = this.$vuetify.lang.t('$vuetify.services.eWalletDescription');

  clickCollectDescription = this.$vuetify.lang.t('$vuetify.services.clickCollectDescription');

  roomServiceDescription = this.$vuetify.lang.t('$vuetify.services.roomServiceDescription');

  contactDescription = this.$vuetify.lang.t('$vuetify.services.contactDescription');

  mediaDescription = this.$vuetify.lang.t('$vuetify.services.mediaDescription');

  @Watch('$store.state.site.links')
  setLinks(): void {
    this.services = [];
    if (this.links) {
      const clickAndCollect = {
        title: 'Click & collect',
        name: this.routes.clickAndCollect,
        link: this.links.clickAndCollectUrl || null,
        show: !!this.links.clickAndCollectUrl,
        description: this.clickCollectDescription,
        icon: this.svgIcons.shopping,
        shortName: this.$vuetify.lang.t('$vuetify.services.shortNameClickCollect'),
      };
      const badge = {
        title: 'E-wallet',
        name: this.routes.badge,
        link: this.links.badgeUrl || null,
        show: !!this.links.badgeUrl,
        description: this.eWalletDescription,
        icon: this.svgIcons.wallet,
        shortName: 'E-wallet',
      };
      const affluence = {
        title: this.$vuetify.lang.t('$vuetify.services.shortNameAffluence'),
        name: this.routes.affluence,
        link: this.links.affluenceUrl || null,
        show: !!this.links.affluenceUrl,
        description: this.$vuetify.lang.t('$vuetify.services.affluenceDescription'),
        icon: this.svgIcons.poll,
        shortName: this.$vuetify.lang.t('$vuetify.services.shortNameAffluence'),
      };
      const roomService = {
        title: 'Room Service',
        name: this.routes.roomService,
        link: this.links.roomServiceUrl || null,
        show: !!this.links.roomServiceUrl,
        description: this.roomServiceDescription,
        icon: this.svgIcons.roomService,
        shortName: 'Room Service',
      };
      const articles = {
        title: this.$vuetify.lang.t('$vuetify.services.shortNameNews'),
        name: this.routes.articles,
        show: this.hasArticles,
        description: this.newsDescription,
        icon: this.svgIcons.newspaper,
        shortName: this.$vuetify.lang.t('$vuetify.services.shortNameNews'),
      };
      if (this.menusAvailable) this.services.push(this.menuService);
      if (this.hasArticles) this.services.push(articles);
      if (clickAndCollect.show) this.services.push(clickAndCollect);
      if (roomService.show) this.services.push(roomService);
      if (affluence.show) this.services.push(affluence);
      if ((this.enableBooking && !!this.hasBookingConfig) || this.links.bookingUrl)
        this.services.push(this.bookingService);
      if (badge.show) this.services.push(badge);
      if (this.enableContact) this.services.push(this.contactService);
    }
  }

  @Watch('$store.state.menu.menus')
  showLocalService(): void {
    this.setLinks();
  }

  @Watch('$store.state.article.articles')
  showLArticles(): void {
    this.setLinks();
  }

  mounted(): void {
    // this.setServices();
    this.setLinks();
  }

  selectService(service: {name: string; params: {slug: string}}): void {
    if (this.$route.name !== service.name) this.$router.push(service);
  }

  onClickOutside(): void {
    this.updateOpenSelector(false);
  }

  redirectNewUser(): void {
    if (this.isNewUser) {
      // this.$router.push({
      //   name: this.routes.updatePassword,
      //   params: {slug: this.$route.params.slug},
      // });
      window.open(`${this.links.badgeUrl}login`, '_blank');
      this.logout(this.accessToken);
    }
  }

  get contactService() {
    return {
      title: this.$vuetify.lang.t('$vuetify.banner.contactTitle'),
      name: this.routes.contact,
      description: this.contactDescription,
      icon: this.svgIcons.textBox,
      show: this.enableContact,
      shortName: 'Contact',
    };
  }

  get mediaService() {
    let title = this.$vuetify.lang.t('$vuetify.services.shortNameMedias');
    if (this.hasOneMedia) {
      title =
        this.$vuetify.lang.current === 'en' && this.medias[0].nameEn
          ? this.medias[0].nameEn
          : this.medias[0].name;
    }
    return {
      title,
      name: this.routes.medias,
      description: this.mediaDescription,
      icon: this.svgIcons.book,
      show: this.hasMedias,
      shortName: title,
    };
  }

  get accountService() {
    return {
      title: this.$vuetify.lang.t('$vuetify.services.shortNameAccount'),
      description: this.$vuetify.lang.t('$vuetify.services.accountDescription'),
      icon: this.svgIcons.account,
    };
  }

  get bookingService() {
    return {
      title: this.$vuetify.lang.t('$vuetify.services.shortNameBooking'),
      description: this.$vuetify.lang.t('$vuetify.services.bookingDescription'),
      icon: this.svgIcons.tableChair,
      show: (this.enableBooking && this.hasBookingConfig) || !!this.links.bookingUrl,
      name: this.routes.booking,
      shortName: this.$vuetify.lang.t('$vuetify.services.shortNameBooking'),
    };
  }

  get menuService() {
    return {
      title: 'Menus',
      name: this.routes.menu,
      show: this.menusAvailable,
      description: this.menuDescription,
      icon: this.svgIcons.silverware,
      shortName: 'Menus',
    };
  }

  goHome(): void {
    if (
      this.$vuetify.breakpoint.smAndDown &&
      this.$route.name !== this.routes.home &&
      !this.accessToken
    ) {
      this.selectService({
        name: this.routes.home,
        params: {slug: this.$route.params.slug},
      });
    }
  }
}
