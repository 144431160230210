import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBottomNavigation,{attrs:{"app":"","color":"primary","height":"75"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_vm._l((_vm.services.slice(
      0,
      _vm.mediaService.show || (_vm.enableAffluence && _vm.customerId) ? 1 : 2
    )),function(service,index){return _c(VBtn,{key:index,staticClass:"mr-2 text-navigationBtn pr-3 pl-4 py-4",attrs:{"text":"","rounded":"","ripple":false,"value":service.name},on:{"click":function($event){return _vm.selectService({
        name: service.name,
        params: {slug: _vm.$route.params.slug},
      })}}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" "+_vm._s(service.icon)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v(_vm._s(service.shortName))])],1)])}),(_vm.enableAffluence && _vm.customerId)?_c(VDialog,{attrs:{"persistent":_vm.$vuetify.breakpoint.smAndDown,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2 text-navigationBtn px-3 py-4",attrs:{"text":"","exact":"","rounded":"","ripple":false}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.poll)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.services.shortNameAffluence')))])],1)])]}}],null,false,1856431263),model:{value:(_vm.affluenceDialog),callback:function ($$v) {_vm.affluenceDialog=$$v},expression:"affluenceDialog"}},[_c('AffluenceModal',{on:{"closeModal":function($event){_vm.affluenceDialog = false}}})],1):_vm._e(),(_vm.mediaService.show)?_c(VBtn,{staticClass:"mr-2 text-navigationBtn pr-3 pl-4 py-4",attrs:{"text":"","rounded":"","ripple":false,"value":_vm.mediaService.name},on:{"click":function($event){return _vm.selectService({
        name: _vm.mediaService.name,
        params: {slug: _vm.$route.params.slug},
      })}}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.mediaService.icon)+" ")]),_c('span',{staticClass:"pt-1 text-capitalize"},[_vm._v(_vm._s(_vm.mediaService.shortName))])],1)]):_vm._e(),(_vm.services.length > (_vm.mediaService.show || (_vm.enableAffluence && _vm.customerId) ? 1 : 2))?_c(VBottomSheet,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"text-navigationBtn pl-3 pr-4 py-4"},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.apps)+" ")]),_c('span',{staticClass:"pt-1"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.services.shortNameOthers')))])],1)])]}}],null,false,3596671449),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c(VList,{staticClass:"rounded-lg rounded-b-0 pb-8"},_vm._l((_vm.services.slice(
          _vm.mediaService.show || (_vm.enableAffluence && _vm.customerId) ? 1 : 2,
          _vm.services.length
        )),function(service){return _c(VListItem,{key:service.name,on:{"click":function($event){(_vm.sheet = false),
            _vm.selectService({
              name: service.name,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"size":"32px","tile":""}},[_c(VIcon,{attrs:{"color":"primary","medium":""}},[_vm._v(" "+_vm._s(service.icon)+" ")])],1)],1),_c(VListItemTitle,{staticClass:"text-h2 font-weight-medium"},[_vm._v(_vm._s(service.title || service.name))])],1)}),1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }