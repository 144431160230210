import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c(VAppBar,{attrs:{"color":"white","height":"70"}},[_c(VContainer,{staticClass:"pa-0 fill-height",class:{home: _vm.$route.name === _vm.routes.home}},[_c('div',{staticClass:"Appbar d-flex align-center justify-space-between fill-width"},[_c('transition',{attrs:{"name":"app-bar"}},[_c('div',{staticClass:"d-flex"},[_c(VImg,{directives:[{name:"show",rawName:"v-show",value:((!_vm.isOpenSelector || !_vm.customerId) && !_vm.isDemo && _vm.customerId !== 'WPP'),expression:"(!isOpenSelector || !customerId) && !isDemo && customerId !== 'WPP'"}],staticClass:"Header__logo shrink mr-2",attrs:{"contain":"","position":_vm.siteList.length > 1 || _vm.logo2 ? 'left center' : 'center center',"src":_vm.siteList.length > 1 || _vm.logo2
                    ? require('../assets/images/logos/logo_short.png')
                    : require('../assets/images/logos/logo.png'),"transition":"scale-transition","width":_vm.siteList.length > 1 || _vm.logo2 ? 50 : 120,"height":"60"}})],1)]),(_vm.siteList.length > 1)?_c('SelectorSite',{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],attrs:{"isOpenFromHome":true}}):_vm._e(),_c('Account',{attrs:{"fromAppBar":true}})],1)])],1)],1):_c(VAppBar,{attrs:{"color":"white","height":"70"}},[_c(VContainer,{staticClass:"pa-0 fill-height"},[_c('div',{staticClass:"d-flex align-center flex-grow-0"},[(!_vm.isDemo)?_c(VImg,{staticClass:"Header__logo shrink mr-2",attrs:{"contain":"","src":require('../assets/images/logos/logo.png'),"transition":"scale-transition","width":"100","height":"60"}}):_vm._e(),(_vm.customerId && _vm.siteList.length > 1)?_c('SelectorSite',{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"SelectorSite",attrs:{"isOpenFromHome":true}}):_vm._e()],1),_c(VSpacer,{staticClass:"flex-grow-1"}),_vm._l((_vm.services.slice(0, 3)),function(service){return _c('div',{key:service.name,staticClass:"d-flex align-center pl-2 flex-grow-0"},[(_vm.customerId)?_c(VBtn,{staticClass:"mr-2",attrs:{"exact":"","text":"","plain":"","active-class":"primary--text","ripple":false,"input-value":_vm.$route.name === service.name ||
            (service.name === _vm.routes.menu && _vm.$route.name === _vm.routes.home)},on:{"click":function($event){return _vm.selectService({
              name: service.name,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c('span',[_vm._v(_vm._s(service.shortName))])]):_vm._e()],1)}),_c('div',{staticClass:"d-flex align-center"},[(_vm.enableAffluence && _vm.customerId)?_c(VDialog,{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","plain":"","active-class":"primary--text","ripple":false}},'v-btn',attrs,false),on),[_vm._v(" Affluence ")])]}}],null,false,2020958028),model:{value:(_vm.affluenceDialog),callback:function ($$v) {_vm.affluenceDialog=$$v},expression:"affluenceDialog"}},[_c('AffluenceModal',{on:{"closeModal":function($event){_vm.affluenceDialog = false}}})],1):_vm._e()],1),(_vm.mediaService.show)?_c('div',{staticClass:"d-flex align-center pl-2 flex-grow-0"},[_c(VBtn,{staticClass:"mr-2",attrs:{"exact":"","text":"","plain":"","active-class":"primary--text","ripple":false,"input-value":_vm.$route.name === _vm.mediaService.name},on:{"click":function($event){return _vm.selectService({
              name: _vm.mediaService.name,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c('span',[_vm._v(_vm._s(_vm.mediaService.shortName))])])],1):_vm._e(),(_vm.services.length > 3)?_c(VMenu,{staticClass:"flex-grow-0",attrs:{"bottom":"","left":"","color":"white","close-on-content-click":false,"transition":"scale-transition","offset-y":"","z-index":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"elevation":"0","text":"","plain":"","ripple":false}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.services.shortNameOthers'))+" ")])]}}],null,false,2359853088)},[_c(VList,{staticClass:"rounded-lg rounded-b-0 pb-4"},_vm._l((_vm.services.slice(3)),function(service){return _c(VListItem,{key:service.name,on:{"click":function($event){return _vm.selectService({
                name: service.name,
                params: {slug: _vm.$route.params.slug},
              })}}},[_c(VListItemTitle,{staticClass:"text-h2 font-weight-medium"},[_vm._v(" "+_vm._s(service.shortName)+" ")])],1)}),1)],1):_vm._e(),_c('Account',{attrs:{"fromAppBar":true}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }