import { render, staticRenderFns } from "./MediaItemModal.vue?vue&type=template&id=2e03ba6e&scoped=true"
import script from "./MediaItemModal.vue?vue&type=script&lang=ts"
export * from "./MediaItemModal.vue?vue&type=script&lang=ts"
import style0 from "./MediaItemModal.vue?vue&type=style&index=0&id=2e03ba6e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e03ba6e",
  null
  
)

export default component.exports