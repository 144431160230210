



























































import {Component, Vue} from 'vue-property-decorator';

import {mdiSvg} from '../tools';

@Component({})
export default class LanguageModal extends Vue {
  svgIcons = mdiSvg;

  textFr = {
    title: 'Veuillez sélectionner votre langue!*',

    cta: 'Confirmer',
    helper: '* Vous pourrez à tout moment changer la langue, dans la section ”Compte”.',
  };

  textEn = {
    title: 'Please select your language !*',

    cta: 'Confirm',
    helper: '* You can change the language at any time in the “Account" section.',
  };

  items = [
    {
      name: 'Anglais',
      nameEn: 'English',
      code: 'en',
    },
    {
      name: 'Français',
      nameEn: 'French',
      code: 'fr',
    },
  ];

  selectLang = this.$vuetify.lang.current;

  currentText = this.$vuetify.lang.current === 'fr' ? this.textFr : this.textEn;

  setLng() {
    this.$vuetify.lang.current = this.selectLang;
    localStorage.setItem('lng', this.$vuetify.lang.current);
    window.location.reload();
  }
}
