










































































































































































import {VueClass} from 'vue-class-component/lib/declarations';
import {Component, Vue} from 'vue-property-decorator';
import {VueRecaptcha} from 'vue-recaptcha';
import {validationMixin} from 'vuelidate';
import {alphaNum, email, required} from 'vuelidate/lib/validators';
import {Action, Getter} from 'vuex-class';

import {nameRoutes, PhoneTools, SITEKEY} from '@/core';

import {FeedbackContact, FeedBackProduct, FormContact, Note} from '../interfaces';

@Component({
  components: {VueRecaptcha: VueRecaptcha as VueClass<Vue>},
  mixins: [validationMixin],
  validations: {
    form: {
      name: {required},
      email: {required, email},
      mobile: {
        isValid(mobile: string) {
          if (mobile) {
            const mobileNumber = new PhoneTools(mobile, 'FR');
            return mobileNumber.isValid();
          }
          return false;
        },
      },
      company: {required},
      message: {required},
      badgeId: {alphaNum},
    },
  },
})
export default class Contact extends Vue {
  @Action('reset', {namespace: 'feedback'}) resetError!: () => void;

  @Action('sendFeedback', {namespace: 'feedback'}) sendFeedback!: (
    feedbackContact: FeedbackContact
  ) => void;

  @Action('setFeedBackProduct', {namespace: 'feedback'}) setFeedBackProduct!: (
    feedBackProduct: FeedBackProduct | null
  ) => void;

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('feedBackProduct', {namespace: 'feedback'}) feedBackProduct!: FeedBackProduct | null;

  @Getter('error', {namespace: 'feedback'}) error!: boolean;

  @Getter('errorMessage', {namespace: 'feedback'}) errorMessage!: string;

  @Getter('loading', {namespace: 'feedback'}) loading!: boolean;

  @Getter('response', {namespace: 'feedback'}) response!: boolean;

  dialog = false;

  selectedNote: Note | null = null;

  form: FormContact = {
    customerId: '',
    name: '',
    mobile: '',
    email: '',
    message: '',
    badgeId: undefined,
  };

  valid = false;

  siteKey = SITEKEY;

  reCaptchaToken = '';

  // mounted(): void {}

  nameErrors(prop: string): string[] {
    const errors: string[] = [];
    if (!this.$v.form[prop]?.$dirty) return errors;
    if (!this.$v.form[prop]?.required) errors.push('Ce champ est obligatoire');
    return errors;
  }

  emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.form.email?.$dirty) return errors;
    if (!this.$v.form.email?.email) errors.push("L'email doit être valide");
    if (!this.$v.form.email?.required) errors.push('Ce champ est obligatoire');
    return errors;
  }

  mobileErrors(): string[] {
    const errors: string[] = [];
    if (this.$v.form.mobile?.$model && !this.$v.form.mobile?.isValid) {
      errors.push('Le numéro de téléphone doit être valide');
    }
    return errors;
  }

  badgeIdErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.form.badgeId?.alphaNum) errors.push('Le badge doit être valide');
    return errors;
  }

  submitForm(): void {
    const body = `${this.form.mobile ? `Numéro de téléphone: ${this.form.mobile}\n` : ''}${
      this.form.badgeId ? `Numéro de badge: ${this.form.badgeId}\n` : ''
    }${this.form.message}`;

    const feedbackContact: FeedbackContact = {
      customerId: this.customerId,
      email: this.form.email,
      name: this.form.name,
      token: this.reCaptchaToken,
      body,
    };
    this.sendFeedback(feedbackContact);
  }

  verifyReCaptcha(token: string): void {
    this.reCaptchaToken = token;
  }

  resetForm(): void {
    this.setFeedBackProduct(null);
    this.$v.$reset();
    this.resetError();
    this.form = {
      customerId: '',
      name: '',
      mobile: '',
      email: '',
      message: '',
      badgeId: undefined,
    };
    this.dialog = false;
  }

  goHome(): void {
    this.$router.push({name: nameRoutes.home, params: {slug: this.$route.params.slug}});
  }
}
