import VueI18n from 'vue-i18n';
import {en} from 'vuetify/src/locale';

export const messagesEn: VueI18n.LocaleMessages = {
  en: {
    $vuetify: {
      ...en,
      close: 'Close',
      loading: 'Loading...',
      next: 'Next',
      home: 'Home',
      goHome: 'Go back to home',
      form: {
        submit: 'Submit',
        required: 'This field is required',
        optional: '(optional)',
        lastName: 'Last Name',
        firstName: 'First Name',
        email: 'Email address',
        phoneNumber: 'Phone Number',
        commentary: 'Comment',
        errorEmail: 'The email must be valid',
        errorPhone: 'The phone number must be valid',
        placeholderMessage: 'Describe your request here',
      },
      services: {
        menuDescription: 'Check the restaurant menu',
        newsDescription: 'View the news of your restaurant',
        eWalletDescription: 'Top up/consult your account online',
        clickCollectDescription: 'Takeaway',
        roomServiceDescription: 'Room Service',
        contactDescription: 'Do you have a question?',
        mediaDescription: 'View restaurant information',
        affluenceDescription: 'Find the ideal time to eat',
        accountDescription: 'Access your personal account',
        bookingDescription: 'Table reservation',
        shortNameClickCollect: 'Takeaway',
        shortNameAffluence: 'Attendance',
        shortNameAccount: 'My Account',
        shortNameMedias: 'Media',
        shortNameBooking: 'Reservation',
        shortNameNews: 'News',
        shortNameOthers: 'Others',
      },
      banner: {
        title: 'Welcome to your dining area',
        menuTitle: 'Consult the weekly menu',
        articleTitle: 'Discover our latest news',
        badgeTitle: 'Access your personal account',
        clickAndCollectTitle: 'Online ordering',
        roomServiceTitle: 'Room Service',
        contactTitle: 'Contact form',
        resetPasswordTitle: 'Reset password',
        updatePasswordTitle: 'Update password',
        mediaTitle: 'View restaurant information',
        bookingTitle: 'Book a table',
        cancelBookingTitle: 'Cancel your reservation?',
        affluenceTitle: 'Attendance',
        otherTitle: 'Others',
        bookingStepOneTitle: '1. Reservation details',
        bookingStepTwoTitle: '2. Reservation confirmation',
      },
      menu: {
        allergens: {
          ARA: 'Peanuts',
          CEL: 'Celery',
          CRU: 'Crustaceans',
          FCQ: 'Tree Nuts',
          GLU: 'Gluten',
          LAI: 'Milk',
          LUP: 'Lupin',
          MOL: 'Mollusks',
          MOU: 'Mustard',
          OEU: 'Egg',
          POI: 'Fish',
          SES: 'Sesame',
          SOJ: 'Soy',
          SUL: 'Sulfite',
        },
        selectProductType: {
          ENT: 'Starters',
          PLA: 'Dishes',
          ACC: 'Side Dishes',
          DES: 'Desserts',
          BOI: 'Beverages',
          WRA: 'Wrap',
          FOC: 'Focaccia',
          SAL: 'Salads',
          BAG: 'Bagels',
          BRE: 'Breads',
        },
        modalProduct: {
          certification: 'This dish is certified',
          sideDishes: 'Side Dishes',
          price: 'Price',
        },
      },
      affluence: {
        title: 'Attendance',
        description: 'Check the real time attendance level and find the ideal time to eat',
        days: {
          monday: 'Mon.',
          tuesday: 'Tue.',
          wednesday: 'Wed.',
          thursday: 'Thu.',
          friday: 'Fri.',
        },
        direct: 'Live',
        helper: 'Click on the crowd data for more information',
        redirectNow: 'Check the live crowd level',
        emptyStep: 'No selected step',
      },
      booking: {
        createNewBooking: 'Create a new booking',
        hour: 'Hour',
        countSeats: 'Number of people',
        errorSeats: 'Please enter a number of people greater than 0',
        infoThresholdPartOne: 'For any reservation beyond',
        infoThresholdPartTwo:
          'your reservation will need to be validated by our manager. You will receive a response by email as soon as possible, thank you.',
        shortPeople: 'pers.',
        response: {
          successRegisteredTitle: 'Your reservation has been registered',
          successConfirmedTitle: 'Your reservation is confirmed',
          successDescriptionAt: ' at',
          successDescriptionFor: 'for',
          successRegisteredDescriptionPartOne: ' Thank you for your reservation request for',
          successRegisteredDescriptionPartFour:
            'people, you will receive a response by email as soon as possible.',
          successConfirmedDescriptionPartOne:
            'A confirmation has been sent to your email address for a reservation on',
          successConfirmedDescriptionPartFour:
            'people. For any cancellation, you will find a link in your confirmation email.',
          errorTitle: 'Error',
          error: 'An error occurred during the reservation',
          errorDescription: 'An error occurred, please try again later',
        },
      },
      contact: {
        title: 'A question? A problem?',
      },
      account: {
        balance: 'Balance',
        recharge: 'Recharge my card',
        logout: 'Logout',
        myAccount: 'My Account',
        accountDescription: 'Log in to manage and view your account.',
      },
      login: {
        welcome: 'Welcome',
        account: 'to your personal space',
        login: 'Login',
        resetPassword: 'Forgot password?',
        password: 'Password',
        connectMe: 'Connect',
        textCreateAccount: "Don't have an account?",
        createAccount: 'Sign up',
        textFirstConnection: 'First connection?',
        firstConnection: 'Activate your account',
      },
      helperLogin: {
        description:
          'To access online ordering and/or e-wallet services, please log on into your personal account',
        login: 'Login',
      },
    },
  },
};
