/* eslint-disable @typescript-eslint/ban-types */
import MediaService from '../services/MediaService';

export const actions = {
  getMedias: async ({commit}: {commit: Function}, id: string): Promise<void> => {
    MediaService.getMedias(id).then((response) => {
      commit('media/SET_MEDIAS', response.data, {root: true});
    });
  },
};
