import {RouteConfig} from 'vue-router';

import {nameRoutes} from '@/core';

import Medias from './components/Medias.vue';

export const mediaRoutes: Array<RouteConfig> = [
  {
    path: '/:slug/medias',
    name: nameRoutes.medias,
    component: Medias,
    meta: {title: 'Medias'},
  },
];
