import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[(_vm.$route.name !== _vm.routes.notFound && !!_vm.siteName)?_c('AppBar',{staticClass:"AppBar"}):_vm._e(),(_vm.updateExists || _vm.isOffline)?_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'container' : 'Alert'},[(_vm.updateExists && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))?_c(VAlert,{staticClass:"rounded-1 my-2",attrs:{"dense":"","type":"info","dismissible":"","max-width":"100%"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[_c('span',{staticClass:"caption"},[_vm._v("Une nouvelle version est disponible.")])]),_c(VCol,{staticClass:"shrink"},[_c(VBtn,{attrs:{"small":""},on:{"click":_vm.refreshApp}},[_c('span',{staticClass:"caption"},[_vm._v("Rafraîchir")])])],1)],1)],1):_vm._e(),(_vm.isOffline && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))?_c(VAlert,{staticClass:"rounded-1 my-2",attrs:{"dense":"","icon":_vm.svgIcons.alert,"type":"warning","dismissible":"","max-width":"100%"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[_c('span',{staticClass:"caption"},[_vm._v("Vous êtes hors connection.")])])],1)],1):_vm._e()],1):_vm._e(),(
      (_vm.$vuetify.breakpoint.smAndDown && !!_vm.siteName && _vm.$route.name !== _vm.routes.menu) ||
      (!_vm.$vuetify.breakpoint.smAndDown &&
        !!_vm.siteName &&
        _vm.$route.name !== _vm.routes.menu &&
        _vm.$route.name !== _vm.routes.home) ||
      (_vm.days.length > 0 && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))
    )?_c('Banner'):_vm._e(),_c(VMain,{staticClass:"background",class:_vm.$vuetify.breakpoint.smAndDown && _vm.$route.name !== _vm.routes.notFound
        ? !!_vm.siteName &&
          _vm.$route.name !== _vm.routes.clickAndCollect &&
          _vm.$route.name !== _vm.routes.badge &&
          _vm.$route.name !== _vm.routes.roomService &&
          _vm.$route.name !== _vm.routes.resetPassword
          ? 'mainMobile'
          : ''
        : (!!_vm.siteName &&
            _vm.$route.name !== _vm.routes.menu &&
            _vm.$route.name !== _vm.routes.home &&
            _vm.$route.name !== _vm.routes.menu &&
            _vm.$route.name !== _vm.routes.badge &&
            _vm.$route.name !== _vm.routes.clickAndCollect &&
            _vm.$route.name !== _vm.routes.resetPassword &&
            !(_vm.$route.name === _vm.routes.booking && _vm.links && _vm.links.bookingUrl) &&
            _vm.$route.name !== _vm.routes.roomService) ||
          (_vm.days.length > 0 && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))
        ? 'mainDesktop'
        : ''},[(_vm.$vuetify.breakpoint.smAndDown)?_c('transition',{attrs:{"name":"app","mode":"out-in"}},[_c('router-view')],1):_c('router-view'),(_vm.links && _vm.links.clickAndCollectUrl)?_c('ClickAndCollect',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === _vm.routes.clickAndCollect),expression:"$route.name === routes.clickAndCollect"}]}):_vm._e()],1),(
      _vm.$vuetify.breakpoint.smAndDown &&
      _vm.$route.name !== _vm.routes.notFound &&
      _vm.$route.name !== _vm.routes.home
    )?_c('BottomBar'):_vm._e(),_c(VOverlay,{attrs:{"absolute":false,"value":_vm.isOpenSelector && _vm.$route.name !== _vm.routes.home,"color":"secondary"}}),(_vm.languageDialog)?_c(VDialog,{attrs:{"max-width":"700px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":function($event){_vm.languageDialog = false}},model:{value:(_vm.languageDialog),callback:function ($$v) {_vm.languageDialog=$$v},expression:"languageDialog"}},[_c('LanguageModal',{on:{"closeModal":function($event){_vm.languageDialog = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }