










import {Component} from 'vue-property-decorator';

import BaseService from '../components/BaseService.vue';
import Iframe from '../components/Iframe.vue';

@Component({components: {Iframe}})
export default class Other extends BaseService {}
